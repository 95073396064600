import React from 'react'
import style from './description.m.scss'
import {Banner} from "../../components/banner";
import {WorkBanner} from "../../components/work-banner";
import {ChooseBanner} from "../../components/choose-banner";
import {SectionImageText} from "pages/home/section-image-text";
import {SectionTextImage} from "pages/home/section-text-image";
import {WhitePaperSection} from "pages/home/white-paper-section";
import {SectionImageText1} from "pages/home/section-image-text1";

export const Description = () => {
    return <div className={style.root}>
        <Banner/>
        <SectionImageText/>
        <WorkBanner/>
        <SectionTextImage/>
        <div className={style.container}>
            <div className={style.whitePaperSection}>
                <WhitePaperSection/>
                <WhitePaperSection/>
            </div>
        </div>
        <ChooseBanner/>
        <SectionImageText1/>
    </div>
}
