import React from 'react'
import style from './pricing-structure-card.m.scss'
import {Button} from "components/button";

export const PricingStructureCard = () => {
    return <div className={style.root}>
        <div className={style.title}><h1>Pricing Structure</h1></div>
        <h2 className={style.subtitle}>SELECT A PACKAGE TO VIEW PRICING</h2>
        <div className={style.priceContainer}>
            <div className={style.wrapperSessions}>
                <div className={style.prices}>
                    <Button label="1 Session" className={style.button}/>
                    <Button label="20 Sessions" className={style.button}/>
                    <Button label="40 Sessions" className={style.button}/>
                </div>
                <div className={style.prices}>
                    <Button label="10 Sessions" className={style.button}/>
                    <Button label="30 Sessions" className={style.button}/>
                    <Button label="50 Sessions" className={style.button}/>
                </div>
            </div>
            <div className={style.wrapperPerSession}>
                <div className={style.perSession}>
                    <span className={style.price}>&#36; 0.00</span>
                    <span className={style.perSessionLabel}>Per Session</span>
                    <span className={style.total}>Package Total: &#36;0.00 </span>
                </div>
            </div>
        </div>
        <div className={style.lastRow}>
            <h2>Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit
            </h2>
            <span>Cost Assistance Form</span>
        </div>
    </div>
}
