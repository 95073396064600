import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import React from 'react'
import 'style/global.scss'
import {HomePage} from "pages/home/home-page";
import {Layout} from "components/layout";
import {TestimonialsPage} from "pages/testimonials/testimonials-page";
import {PatientInfoPage} from "pages/patient-info/patient-info-page";
import {AboutUsPage} from "pages/about-us/about-us-page";
import {PatientPortalPage} from "pages/patient-portal/patient-portal-page";
import {RequestVisitPage} from "pages/request-visit/request-visit-page";
import {PrivacyPolicyPage} from "pages/privacy-policy/privacy-policy-page";
import {CareersPage} from "pages/careers/careers-page";
import {NewCustomerFormsPage} from "pages/new-customer-forms/new-customer-forms-page";


createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <Layout>
            <Routes>
                <Route index element={<HomePage/>}></Route>
                <Route path='/testimonials' element={<TestimonialsPage/>}></Route>
                <Route path='/patient-info' element={<PatientInfoPage/>}></Route>
                <Route path='/about-us' element={<AboutUsPage/>}></Route>
                <Route path='/patient-portal' element={<PatientPortalPage/>}></Route>
                <Route path='/request-visit' element={<RequestVisitPage/>}></Route>
                <Route path='/privacy-policy' element={<PrivacyPolicyPage/>}></Route>
                <Route path='/new-customer-forms' element={<NewCustomerFormsPage/>}></Route>
                <Route path='/careers' element={<CareersPage/>}></Route>
            </Routes>
        </Layout>
    </BrowserRouter>
)
