import React from 'react'
import style from './banner.m.scss'
import logo from 'Resource/logo.svg'


export const Banner = () => {
    return <div className={style.root}>
        <h1 className={style.title}>What is</h1>
        <div className={style.wrapper}>
            <img alt='logo' src={logo} className={style.logo}/>
            <h1 className={style.questionMark}>?</h1>
        </div>
    </div>
}
