import React from 'react'
import style from './button.m.scss'
import classNames from "classnames";

export const Button = ({label, className, onClick}) => {
    return <button
        className={classNames(style.root, className)}
        onClick={onClick}>{label}
    </button>
}
