import React from 'react'
import style from 'pages/home/section-image-text1.m.scss'
import imageIcon from 'Resource/image-icon.svg'
import {Paragraph} from "pages/home/paragraph";


export const SectionImageText1 = () => {
    return <div className={style.root}>
        <div className={style.container}>
            <div className={style.blueCircle}>
                <img src={imageIcon}/>
            </div>
            <Paragraph
                text={
                    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh\n' + 'euismod tincidunt utlaoreet dolore magna aliquam erat volutpat. Ut wisi enim ad\n' +
                    'minim veniam, quis nostrud exerci'}
                className={style.paragraph}
            />
        </div>
    </div>
}
