import React from 'react';
import style from 'components/footer.m.scss';
import facebook from 'Resource/facebook.svg'
import linkedIn from 'Resource/linkedIn.svg'
import instagram from 'Resource/instagram.svg'
import youtube from 'Resource/youtube.svg'
import twitter from 'Resource/twitter.svg'
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <div className={style.footer}>
            <div className={style.upperRow}>
                <div className={style.section}>
                    <h2>Location</h2>
                    <span>1038 W 75 N</span>
                    <span>Centerville, UT 84014</span>
                </div>
                <div className={style.section}>
                    <h2>Phone</h2>
                    <span>(801)555-5555</span>
                </div>
                <div className={style.section}>
                    <h2>Operating Hours</h2>
                    <span>M-F</span>
                    <span>8AM-5PM</span>
                </div>
                <div className={style.section}>
                    <h2>Torque3</h2>
                    <a>www.torque3.com</a>
                </div>
            </div>

            <div className={style.lowerRow}>
                <div className={style.section}>
                    <Link
                        to='/new-customer-forms'
                    >New Customer Forms
                    </Link>
                    <Link
                        to='/careers'
                    >Careers
                    </Link>
                </div>
                <div className={style.icons}>
                    <div className={style.imageContainer}>
                        <img src={facebook} alt='facebook'></img>
                        <img src={instagram} alt='instagram'></img>
                        <img src={youtube} alt='youtube'></img>
                        <img src={linkedIn} alt='linkedin'></img>
                        <img src={twitter} alt='twitter'></img>
                    </div>
                    <span className={style.allRights}>&#169; 2023 - 2024 All Rights Reserved</span>
                </div>
                <div className={style.section}>
                    <Link
                        to='/privacy-policy'
                    >Privacy Policy
                    </Link>
                    <Link
                        to='/privacy-policy'
                    >Terms of Use
                    </Link>
                </div>
            </div>
        </div>
    );
};
