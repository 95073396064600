import React from 'react'
import style from 'pages/about-us/about-us.m.scss'
import imageIcon from "Resource/image-icon.svg";
//import blueCircle from "Resource/Asset 7.svg";
import videoPlayerIcon from "Resource/Asset 6.svg";

export const AboutUsPage = () => {
    return <div className={style.root}>
        <h1 className={style.title}>Meet Our Team!</h1>
        <div className={style.teamMembersContainer}>
            <div className={style.twoMembers}>
                <div className={style.member}>
                    <svg viewBox="0 -25 300 330">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <h3 className={style.memberName}>MEMBER PHD</h3>
                    <p className={style.memberText}>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
                <div className={style.member}>
                    <svg viewBox="0 -25 300 330">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <h3 className={style.memberName}>MEMBER PHD</h3>
                    <p className={style.memberText}>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
            </div>
            <div className={style.twoMembers}>
                <div className={style.member}>
                    <svg viewBox="0 -25 300 330">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <h3 className={style.memberName}>MEMBER PHD</h3>
                    <p className={style.memberText}>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
                <div className={style.member}>
                    <svg viewBox="0 -25 300 330">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <h3 className={style.memberName}>MEMBER PHD</h3>
                    <p className={style.memberText}>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
            </div>
        </div>
        <h1 className={style.titleGreen}>Platform Development History</h1>
        <div className={style.videoPlayerContainer}>
            <div className={style.blueCircle}/>
                <img src={videoPlayerIcon}></img>
        </div>
        <h1 className={style.title}>Research Opportunities</h1>
        <p className={style.researchText}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
            sed diam nonummy nibh euismod tincidunt utlaoreet
            dolore magna aliquam erat volutpat. Ut wisi enim ad minim
        </p>
    </div>
}
