import React from 'react'
import style from './white-paper-section.m.scss'


export const WhitePaperSection = () => {
    return <div className={style.root}>
        <div className={style.title}>
            <span>White Paper</span>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                magna aliquam erat volutpat. Ut wisi enim ad minim
        </p>
        <span className={style.download}>Download</span>
    </div>
}
