import React from 'react'
import style from "components/navigation-menu.m.scss";
import classNames from "classnames";
import {Link, useMatch} from "react-router-dom";

export const NavigationMenu = ({vertical= false}) => {
    const isAbout = useMatch('/about-us') !== null
    const isTestimonials = useMatch('/testimonials') !== null
    const isPatientInfo = useMatch('/patient-info') !== null

    return <nav className={style.root}>
        <ul className={classNames({
            [style.vertical]: vertical
        })}>
            <li>
                <Link
                    className={classNames({
                        [style.active]: isTestimonials,
                    })}
                    to='/testimonials'
                >Testimonials</Link>
            </li>
            <li>
                <Link to='/patient-info'
                      className={classNames({
                          [style.active]: isPatientInfo,
                      })}
                >Patient Info</Link>
            </li>
            <li>
                <Link
                    className={classNames({
                        [style.active]: isAbout,
                    })}
                    to='/about-us'>About Us</Link>
            </li>
        </ul>
    </nav>
}
