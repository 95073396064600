import React from 'react'
import logo from 'Resource/logo.svg'
import style from "./banner.m.scss";
export const ChooseBanner = () => {
    return <div className={style.root}>
        <h1 className={style.title}>Why choose</h1>
        <div className={style.wrapper}>
            <img alt='logo' src={logo} className={style.logo}/>
            <h1 className={style.questionMark}>?</h1>
        </div>
    </div>
}
