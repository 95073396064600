import React from 'react'
import style from './testimonials-page.m.scss'
import videoPlayerIcon from "Resource/Asset 6.svg";
import imageIcon from 'Resource/image-icon.svg'


export const TestimonialsPage = () => {
    return <div className={style.root}>
        <h1 className={style.title}>Our Stories</h1>
        <div className={style.section}>
            <div className={style.blueCircle}>
                <img src={imageIcon} className={style.image}/>
            </div>

            <div className={style.videoPlayerContainer}>
                <svg viewBox="0 -65 1434 1000">
                    <image href={videoPlayerIcon} width='478' x="478" y="218.5"></image>
                </svg>
            </div>

            <div>
                <img src={imageIcon} className={style.image}/>
            </div>
        </div>
        <h1 className={style.caseStudiesTitle}>Case Studies</h1>
        <div className={style.caseStudiesContainer}>
            <div className={style.twoPersonalTestimonialsGrouped}>
                <div className={style.personalTestimonial}>
                    <svg viewBox="0 -10 300 300">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <div className={style.subjectNameButton}>Subject Name</div>
                    <p>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
                <div className={style.personalTestimonial}>
                    <svg viewBox="0 -10  300 300">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <div className={style.subjectNameButton}>Subject Name</div>
                    <p>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
            </div>
            <div className={style.twoPersonalTestimonialsGrouped}>
                <div className={style.personalTestimonial}>
                    <svg viewBox="0 -10  300 300">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <div className={style.subjectNameButton}>Subject Name</div>
                    <p>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
                <div className={style.personalTestimonial}>
                    <svg viewBox="0 -10  300 300">
                        <image href={imageIcon} width="150" x="75" y="75"></image>
                    </svg>
                    <div className={style.subjectNameButton}>Subject Name</div>
                    <p>Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit,
                        sed diam nonummy nibh
                        euismod tincidunt ut laoreetSubject Name</p>
                </div>
            </div>
        </div>

    </div>
}
