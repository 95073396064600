import React from 'react'
import style from './patient-info.m.scss'
import blueCircle from "Resource/blue-tail.svg"
import dubleCircle from "Resource/Asset 8.svg"
import rider from 'Resource/rider-flipped.png'
import {PricingStructureCard} from "pages/patient-info/pricing-structure-card";


export const PatientInfoPage = () => {
    return <div className={style.root}>
        <h1 className={style.title}>Platform Requirements</h1>

           <div className={style.background}>
               <div className={style.textBackground}>
                    <span>Lorem ipsum dolor sit amet, consectetuer</span>
                   <span>adipiscing elit, sed diam nonummy nibh</span>
                   <span>euismod tincidunt utlaoreet dolore magna</span>
                   <span>aliquam erat volutpat. Ut wisi enim ad</span>
                   <span>minim veniam, quis nostrud exerci</span>
               </div>
               <div className={style.imageBackground}>
                    <img src={rider} alt="Rider" />
               </div>
        </div>
        <PricingStructureCard/>
        <h1 className={style.titleBlue}>What to Expect During Your First Visit?</h1>
        <svg viewBox="0 0 100 70">
            <image href={dubleCircle} width="100"/>
        </svg>
        <h1 className={style.title}>Ready to ROCK?!</h1>
    </div>
}
