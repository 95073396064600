import React from 'react'
import style from './section-text-image.m.scss'
import interview from 'Resource/interview.jpg'
import {Paragraph} from "pages/home/paragraph";



export const SectionTextImage = () => {
    return <div className={style.root}>
       <div className={style.container}>
           <Paragraph
               text={
                   'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh\n' + 'euismod tincidunt utlaoreet dolore magna aliquam erat volutpat. Ut wisi enim ad\n' +
                   'minim veniam, quis nostrud exerci'}
               className={style.paragraph}
           />
           <div className={style.greenCircle}>
               <img src={interview}/>
           </div>
       </div>
    </div>
}
