import React, {useLayoutEffect, useState} from 'react'
import style from './header.m.scss'
import logo from 'Resource/logo.svg'
import hamburger from 'Resource/hamburger.png'
import {useMatch, useNavigate} from "react-router-dom";
import {NavigationMenu} from "components/navigation-menu";
import {Overlay} from "overlay";
import classNames from "classnames";


export const Header = () => {
    const isHome = useMatch('/') !== null
    const navigate = useNavigate()

    const [shouldShowHorizontalMenu, setShouldShowHorizontalMenu] = useState(false)
    const [isVerticalMenuOpen, setIsVerticalMenuOpen] = useState(false)

    useLayoutEffect(() => {
        const handleResize = () => {
            const matchesNewQuery = window.matchMedia(`(max-width: 1200px)`).matches
            if (matchesNewQuery !== shouldShowHorizontalMenu) { setShouldShowHorizontalMenu(matchesNewQuery) }
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [shouldShowHorizontalMenu])

    return <div className={style.root}>
            <label onClick={() => navigate('/')}>
                <img className={classNames(style.logo, {
                    [style.active]: isHome,
                })} src={logo}/>
            </label>
            {
                !shouldShowHorizontalMenu
                    ? <NavigationMenu/>
                    : <label onClick={() => setIsVerticalMenuOpen(old => !old)}>
                        <img className={style.hamburger} src={hamburger}/>
                    </label>
            }

            {shouldShowHorizontalMenu &&
                <Overlay
                    parentProvider={() => document.getElementById('root')}
                    isVisible={isVerticalMenuOpen}
                    onClick={() => { setIsVerticalMenuOpen(false)}}
                >
                    <NavigationMenu vertical={shouldShowHorizontalMenu}/>
                </Overlay>}
    </div>
}
