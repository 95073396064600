import React from 'react'
import style from 'pages/new-customer-forms/new-customer-forms.m.scss'

export const NewCustomerFormsPage = () => {
    return <div className={style.root}>
        <div className={style.container}>
            <script type="text/javascript"
                    src="https://campaigns.zoho.com/js/zc.iframe.js"></script>
            <iframe
                frameBorder="0"
                id="iframewin"
                width="100%"
                height="100%"
                src="https://lsmuel-glf.maillist-manage.com/ua/Optin?od=11287ecc74e21e&zx=132d842a6&tD=1109452f1e276a3c1&sD=1109452f1e279d4a3"></iframe>
        </div>
    </div>
}
