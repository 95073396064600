import React from "react";
import logo from 'Resource/logo.svg'
import style from "./work-banner.m.scss";

export const WorkBanner = () => {
    return <div className={style.root}>
        <h1 className={style.title}>How does</h1>
        <div className={style.wrapper}>
            <img alt='logo' src={logo} className={style.logo}/>
            <h1 className={style.work}> work</h1>
            <h1 className={style.questionMark}>?</h1>
        </div>
    </div>
}
