import React from 'react'
import style from 'pages/home/paragraph.m.scss'
import classNames from "classnames";


export const Paragraph = ({text, className}) => {
    return <p className={classNames(style.root, className)}>
            {text}
        </p>

}
